import React from 'react'
import { Link } from 'react-router-dom'
import './sidebar.scss'

const Sidebar = () => {
  return (
    <div className='sidebar-main'>
        <div className='navigation-item'>
            <Link><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M575.8 255.5c0 18-15 32.1-32 32.1h-32l.7 160.2c0 2.7-.2 5.4-.5 8.1V472c0 22.1-17.9 40-40 40H456c-1.1 0-2.2 0-3.3-.1c-1.4 .1-2.8 .1-4.2 .1H416 392c-22.1 0-40-17.9-40-40V448 384c0-17.7-14.3-32-32-32H256c-17.7 0-32 14.3-32 32v64 24c0 22.1-17.9 40-40 40H160 128.1c-1.5 0-3-.1-4.5-.2c-1.2 .1-2.4 .2-3.6 .2H104c-22.1 0-40-17.9-40-40V360c0-.9 0-1.9 .1-2.8V287.6H32c-18 0-32-14-32-32.1c0-9 3-17 10-24L266.4 8c7-7 15-8 22-8s15 2 21 7L564.8 231.5c8 7 12 15 11 24z"/></svg></Link>
        </div>
        <div className='navigation-item'>
            <Link><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304H178.3z"/></svg></Link>
            
        </div>
        <div className='navigation-item'>
            <Link><svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 viewBox="0 0 512.000000 512.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
fill="#000000" stroke="none">
<path d="M3120 5027 c-49 -16 -133 -102 -148 -153 -7 -23 -12 -84 -12 -137 l0
-95 -47 -7 c-103 -15 -196 -84 -243 -180 -20 -41 -24 -66 -24 -135 0 -73 4
-92 26 -136 15 -28 38 -65 52 -81 33 -39 129 -90 189 -98 l47 -7 0 -199 0
-199 319 0 319 0 4 109 c3 118 12 141 72 185 39 29 133 29 172 0 60 -44 69
-67 72 -185 l4 -109 215 0 c140 0 230 4 257 12 55 16 138 99 154 154 17 58 17
1049 0 1106 -6 22 -31 62 -55 88 -74 83 -50 80 -737 79 -408 -1 -612 -5 -636
-12z m1233 -158 c14 -6 30 -22 36 -36 8 -16 11 -176 11 -513 0 -337 -3 -497
-11 -513 -17 -39 -50 -47 -182 -47 l-122 0 -12 61 c-28 147 -163 259 -313 259
-150 0 -285 -112 -313 -259 l-12 -61 -157 0 -158 0 0 200 0 200 -96 0 c-111 0
-151 14 -191 67 -57 74 -32 182 52 230 34 19 55 23 138 23 l97 0 0 165 c0 172
6 205 44 223 30 15 1157 16 1189 1z"/>
<path d="M1921 4549 c-366 -43 -739 -240 -977 -517 -181 -210 -296 -444 -355
-722 -30 -139 -33 -427 -6 -565 43 -222 143 -458 263 -626 93 -129 198 -233
402 -399 l191 -155 0 -111 1 -112 -30 -12 c-68 -28 -130 -129 -130 -210 0 -85
61 -179 138 -214 37 -17 40 -21 31 -43 -34 -91 24 -224 121 -273 31 -16 38
-25 43 -57 18 -130 118 -279 241 -360 213 -141 509 -114 690 61 83 81 166 237
166 313 0 16 11 28 39 42 98 50 156 183 122 274 -9 22 -6 26 31 43 77 35 138
129 138 214 0 81 -62 182 -130 210 l-30 12 1 112 0 111 157 125 c188 150 356
315 436 428 173 245 271 539 283 855 l6 147 -240 0 -241 0 -7 48 c-8 59 -59
155 -98 188 -16 14 -53 37 -81 52 -44 22 -63 26 -136 26 -69 0 -94 -4 -135
-24 -96 -47 -165 -140 -180 -243 l-7 -47 -159 0 -159 0 0 199 0 199 -109 4
c-118 3 -141 12 -185 72 -29 39 -29 133 0 172 44 60 67 69 185 72 l109 4 0
359 0 359 -162 -1 c-90 -1 -196 -5 -237 -10z m239 -347 l0 -197 -61 -12 c-147
-28 -259 -163 -259 -313 0 -150 112 -285 259 -313 l61 -12 0 -117 0 -118 -158
0 -157 0 -12 61 c-28 147 -163 259 -313 259 -150 0 -285 -112 -313 -259 l-12
-61 -232 0 -233 0 0 36 c0 57 37 225 71 321 69 194 175 360 328 514 259 259
590 400 954 407 l77 2 0 -198z m-547 -955 c53 -40 67 -80 67 -191 l0 -96 240
0 240 0 0 -158 0 -157 -61 -12 c-147 -28 -259 -163 -259 -313 0 -150 112 -285
259 -313 l61 -12 0 -317 0 -318 -280 0 -280 0 0 139 0 140 -212 174 c-117 95
-250 213 -295 260 -208 221 -334 504 -368 825 l-6 62 321 0 320 0 0 98 c0 82
4 103 23 137 48 84 156 109 230 52z m1440 0 c53 -40 67 -80 67 -191 l0 -96
240 0 240 0 -6 -62 c-30 -314 -159 -604 -367 -825 -45 -47 -178 -165 -294
-260 l-213 -174 0 -140 0 -139 -200 0 -200 0 0 400 0 400 -96 0 c-111 0 -151
14 -191 67 -57 74 -32 182 52 230 34 19 55 23 138 23 l97 0 0 240 0 240 240 0
240 0 0 98 c0 82 4 103 23 137 48 84 156 109 230 52z m-219 -2058 c13 -7 30
-25 37 -40 15 -37 -2 -82 -37 -98 -35 -16 -1313 -16 -1348 0 -35 16 -52 61
-37 98 7 16 22 33 34 39 32 15 1317 16 1351 1z m-160 -320 c55 -26 55 -112 0
-138 -35 -16 -993 -16 -1028 0 -35 16 -52 61 -37 98 7 16 22 33 34 39 32 15
997 16 1031 1z m-129 -331 c-30 -106 -112 -206 -205 -251 -228 -111 -495 7
-565 251 l-6 22 391 0 391 0 -6 -22z"/>
</g>
</svg></Link>

        </div>
    </div>
  )
}

export default Sidebar