import React, { useEffect, useState } from 'react';
import Hero8 from '../Hero/Hero8';
import ServiceListStyle2 from '../ServiceList/ServiceListStyle2';
import SectionHeading from '../SectionHeading';
import Spacing from '../Spacing';
import Portfolio from '../Portfolio';
import Div from '../Div';
import { pageTitle } from '../../helper';
import { Icon } from '@iconify/react';
import TestimonialSlider from '../Slider/TestimonialSlider';
import VideoModal from '../VideoModal';
import Cta from '../Cta';
import LogoList from '../LogoList';
import MovingText from '../MovingText';
import { motion, useMotionValueEvent, useScroll } from "framer-motion"
// import video from '../../../public/video/bg-video.mp4'
const heroSocialLinks = [
  {
    name: 'Behance',
    links: '/',
  },
  {
    name: 'Twitter',
    links: '/',
  },
];
const portfolioData = [
  {
    title: 'UX/UI Design',
    subtitle: 'See Details',
    href: '/portfolio/portfolio-details',
    src: '/images/portfolio1.jpg',
    category: 'ui_ux_design',
    pos: 1900
  },
  {
    title: 'Creative Logo Design',
    subtitle: 'See Details',
    href: '/portfolio/portfolio-details',
    src: '/images/logo1.webp',
    category: 'logo_design',
    pos: 1900
  },
  {
    title: 'Creative Web Design',
    subtitle: 'See Details',
    href: '/portfolio/portfolio-details',
    src: '/images/portfolio2.jpg',
    category: 'web_design',
    pos: 2500
  },
  {
    title: 'Mobile Application',
    subtitle: 'See Details',
    href: '/portfolio/portfolio-details',
    src: '/images/app1.webp',
    category: 'mobile_apps',
    pos: 2500
  },
  {
    title: 'Creative UI/UX Design',
    subtitle: 'See Details',
    href: '/portfolio/portfolio-details',
    src: '/images/portfolio16.png',
    category: 'ui_ux_design',
    pos: 3100
  },
  {
    title: 'Web Design',
    subtitle: 'See Details',
    href: '/portfolio/portfolio-details',
    src: '/images/portfolio13.jpg',
    category: 'web_design',
    pos: 3100
  },
  {
    title: 'Creative Logo Design',
    subtitle: 'See Details',
    href: '/portfolio/portfolio-details',
    src: '/images/logo2.png',
    category: 'logo_design',
    ps: 3700
  },
  {
    title: 'UI/UX Design',
    subtitle: 'See Details',
    href: '/portfolio/portfolio-details',
    src: '/images/portfolio14.jpg',
    category: 'ui_ux_design',
    pos: 3700
  },
  {
    title: 'Creative logo Design',
    subtitle: 'See Details',
    href: '/portfolio/portfolio-details',
    src: '/images/logo3.webp',
    category: 'logo_design',
    pos: 4300
  },
  {
    title: 'Creative Web Design',
    subtitle: 'See Details',
    href: '/portfolio/portfolio-details',
    src: '/images/portfolio15.png',
    category: 'web_design',
    pos: 4300
  },
];
const categoryMenu = [
  {
    title: 'Web Design',
    category: 'web_design',
  },
  {
    title: 'UI/UX Design',
    category: 'ui_ux_design',
  },
  {
    title: 'Mobile Apps',
    category: 'mobile_apps',
  },
  {
    title: 'Logo Design',
    category: 'logo_design',
  },
];

export default function FreelancerAgencyHome() {
  pageTitle('Portfolio');
  const [active, setActive] = useState('all');
  const [itemShow, setItemShow] = useState(6);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const { scrollY } = useScroll()
  const [yax, setYax] = useState(0);
  useMotionValueEvent(scrollY, "change", (latest) => {
    // console.log("Page scroll: ", latest)
    setYax(latest)
  })
  return (
    <>
      <Hero8
        title="A LEADING WEB & SOFTWARE COMPANY"
        subtitle="Devop360 established in the year 2015 has all the experience, expertise, and achievements that give the company a vast and diversified portfolio of success. Since its inception, the company has been in varied phases of transformations for betterment and excellence in whatever services we offer. Protruding its list of clients both local and international, Advent Interactive is proud to have worked for a varied jack of projects related to different industries such as hospitality, medicine, education, entertainment, non-profit organizations, and more. We have people crafted with experience, knowledge, and the right skills to do your job in the best possible manner."
        btnLink="/contact"
        btnText="Let’s talk"
        socialLinksHeading="Follow Us"
        heroSocialLinks={heroSocialLinks}
        bgImageUrl="/images/background.jpg"
        bannerHighlightImage="/images/favicon.png"
        spiningCircleUrl="/images/hero_img.svg"
        videosrc ='/video/bg-video.mp4'
      />
      <section className="cs-shape_wrap_4 cs-parallax">
        <div className="cs-shape_4 cs-to_up" />
        <div className="cs-shape_4 cs-to_right" />
        <Spacing lg="145" md="80" />
        <div className="container">
          <div className="row">
            <div className="col-lg-5 col-xl-4">
              <SectionHeading
                title="We provide best value offer"
                subtitle="Services"
                btnLink="/service"
                btnText="See All Services"
              />
              <Spacing lg="45" md="45" />
            </div>
            <div className="col-lg-7 offset-xl-1">
              <ServiceListStyle2 />
            </div>
          </div>
        </div>
      </section>
      <Spacing lg="115" md="55" />

      <Div className="container">
        <Div className="cs-portfolio_1_heading">
          <SectionHeading title="Some recent work" subtitle="Our Portfolio" />
          <Div className="cs-filter_menu cs-style1">
            <ul className="cs-mp0 cs-center">
              <li className={active === 'all' ? 'active' : ''}>
                <span onClick={() => setActive('all')}>All</span>
              </li>
              {categoryMenu.map((item, index) => (
                <li
                  className={active === item.category ? 'active' : ''}
                  key={index}
                >
                  <span onClick={() => setActive(item.category)}>
                    {item.title}
                  </span>
                </li>
              ))}
            </ul>
          </Div>
        </Div>
        <Spacing lg="90" md="45" />
        <Div className="cs_portfolio_grid_2">
          {portfolioData.slice(0, itemShow).map((item, index) => (
            <Div
              className={`${active === 'all'
                ? ''
                : !(active === item.category)
                  ? 'd-none'
                  : ''
                }`}
              key={index}
              motion="yes"
              y={item.pos}
              position={index + 1}

            >
              <Portfolio
                title={item.title}
                subtitle={item.subtitle}
                href={item.href}
                src={item.src}
                variant="cs-style1 cs-type1"
              />
            </Div>
          ))}
        </Div>

        <Div className="text-center">
          {portfolioData.length <= itemShow ? (
            ''
          ) : (
            <>
              <Spacing lg="65" md="40" />
              <span
                className="cs-text_btn"
                onClick={() => setItemShow(itemShow + 4)}
              >
                <span>Load More</span>
                <Icon icon="bi:arrow-right" />
              </span>
            </>
          )}
        </Div>
      </Div>
      <Spacing lg="145" md="80" />
      {/* Start Testimonial Section */}
      <TestimonialSlider />
      {/* End Testimonial Section */}

      {/* Start Video Block Section */}
      <Spacing lg="140" md="70" />
      <Div className="container" > 
        <h2 className="cs-font_50 cs-m0 text-center cs-line_height_4">
          We do more then ever platform
        </h2>
        <Spacing lg="70" md="70" />
        <VideoModal
          style= {{opacity :  0.5}}
          videoSrc="https://www.youtube.com/watch?v=VcaAVWtP48A"
          bgUrl="/images/video_bg_2.jpeg"
        />
        {/* <h2 className="cs-font_50 cs-m0 text-center cs-line_height_4">
          We do more then ever platform
        </h2>
        <Spacing lg="70" md="70" />
        <video src='./video/bg-video.mp4' style={{width: '100%', borderRadius: '28px'}} autoPlay muted loop></video> */}
      </Div>
      {/* End Video Block Section */}

      {/* Start MovingText Section */}
      <Spacing lg="125" md="70" />
      <MovingText text="YOUR PERFECT BUSINESS PARTNER SOLUTION" />
      <Spacing lg="105" md="70" />
      {/* End MovingText Section */}

      {/* Start LogoList Section */}
      <Div className="container" style={{position: 'relative'}}>
        <LogoList />
      </Div>
      <Spacing lg="150" md="80" />
      {/* End LogoList Section */}

      {/* Start CTA Section */}
      <Div className="container">
        <Cta
          title="We're delivering the best <br>customer experience"
          btnText="Apply For Meeting"
          btnLink="/contact"
          bgSrc="/images/cta_bg.jpeg"
        />
      </Div>
      {/* End CTA Section */}
    </>
  );
}
