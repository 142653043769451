import React, { useEffect, useRef, useState } from 'react'
import { motion, useAnimate, useMotionValueEvent, useScroll } from "framer-motion"

export default function Div(props) {
  const [isMobile, setIsMobile] = useState("");
  const elementRef = useRef(null);
  // const rect = elementRef.current.getBoundingClientRect();
  const [mobile , setMobile] = useState(window.innerWidth < 720?true:false);
  
  useEffect(() => {
    if (elementRef.current) {
      const rect = elementRef.current.getBoundingClientRect();
    }
    
  }, []);
  const { scrollY } = useScroll()
  const [yax, setYax] = useState(0);
  useMotionValueEvent(scrollY, "change", (latest) => {
    setYax(latest)
  })
  let right = props.position % 2 == 0;
  let y = props.y;
  let transition = { type: "spring", duration: 1.5 }
  let xpos, ypos;
  let fr,op;
  let [row1style ,setRow1style] = useState({});
  const [initial,setInitial] = useState({ x: 0, y: 0, opacity: 1, rotate: 0 });
  const inView = () => {
    if (y === 1900 && yax > 1950) {
      console.log("first row animation only");
      xpos = right ? yax - y : -(yax - y);
      ypos = (yax / 10) - 150;
      // setMyanimation({ transform: `translate(${xpos}px,${ypos}px)` })
      // setTransX(xpos);
      // setTransY(ypos);
      // console.log(transX, ",", transY);
      console.log(ypos);
      op = (1428.5 / (yax) - 0.3);
      fr = op === 0 ?'moved':'';
      setInitial(fr !== 'moved' ?{ x: 0, y: 0, opacity: 1, rotate: 0 }:{ x: xpos , y : ypos, rotate: right ? (yax - y) / 15 : -(yax - y) / 15, opacity: op })
      console.log(initial);
      setRow1style({ transform: `translate(500px, 500px)` });
      // return { x: xpos , y : ypos, rotate: right ? (yax - y) / 15 : -(yax - y) / 15, opacity: op }
    }
    if (y === 2500 && yax > 2500) {
      console.log("first row animation only");
      xpos = right ? yax - y : -(yax - y);
      ypos = (yax / 10) - 150;
      // setMyanimation({ transform: `translate(${xpos}px,${ypos}px)` })
      // setTransX(xpos);
      // setTransY(ypos);
      return { x: xpos , y : ypos, rotate: right ? (yax - y) / 15 : -(yax - y) / 15, opacity: (1428.5 / (yax) - 0.3) }
    }
    if (y === 3100 && yax > 3100) {
      xpos = right ? yax - y : -(yax - y);
      ypos = (yax / 10) - 150;
      // setMyanimation({ transform: `translate(${xpos}px,${ypos}px)` })
      // setTransX(xpos);
      // setTransY(ypos);
      // console.log(transX, ",", transY);
      return { x: xpos , y : ypos, rotate: right ? (yax - y) / 15 : -(yax - y) / 15, opacity: (1428.5 / (yax) - 0.3) }
    }
  }
  // if (y === 1900 && yax == y) {
  //   console.log("first row animation only");
  //   xpos = right ? yax - y : -(yax - y);
  //   ypos = (yax / 10) - 150;
  //   // setMyanimation({ transform: `translate(${xpos}px,${ypos}px)` })
  //   setTransX(xpos);
  //   setTransY(ypos);
  //   console.log(transX, ",", transY);
  //   console.log(ypos);
  //   // animate(elementRef.current, { opacity: 0 }, { duration: 1 })
  // }

useEffect(()=>{

  if (y === 1900 && yax > 2050) {
    xpos = right ? yax - y -150 : -(yax - y-150);
    ypos = (yax / 15) - 150;
    op = (1428.5 / (yax) - 0.3);
    fr = op === 0 ?'moved':'';
    let rot = right ? (yax - y) / 15 : -(yax - y) / 15;
    setInitial(fr !== 'moved' ?{ x: 0, y: 0, opacity: 1, rotate: 0 }:{ x: xpos , y : ypos, rotate: right ? (yax - y) / 15 : -(yax - y) / 15, opacity: op })

    setRow1style({ transition: "transform 1s" ,  transform: `translate(${xpos}px, ${ypos}px) rotate(${rot}deg)` , opacity: op});

    // return { x: xpos , y : ypos, rotate: right ? (yax - y) / 15 : -(yax - y) / 15, opacity: op }
  }
  else{
    setRow1style({ transition: "transform 1s" , transform: `translate(0px, 0px)` });
  }

  if (y === 2500 && yax > 2650) {
    xpos = right ? yax - y -150 : -(yax - y-150);
    ypos = (yax / 15) - 150;
    // setMyanimation({ transform: `translate(${xpos}px,${ypos}px)` })
    // setTransX(xpos);
    // setTransY(ypos);
    // console.log(transX, ",", transY);
    op = (2000 / (yax) - 0.3);
    fr = op === 0 ?'moved':'';
    let rot = right ? (yax - y) / 15 : -(yax - y) / 15;
    setInitial(fr !== 'moved' ?{ x: 0, y: 0, opacity: 1, rotate: 0 }:{ x: xpos , y : ypos, rotate: right ? (yax - y) / 15 : -(yax - y) / 15, opacity: op })

    setRow1style({ transition: "transform 1s" ,  transform: `translate(${xpos}px, ${ypos}px) rotate(${rot}deg)` , opacity: op});
    // return { x: xpos , y : ypos, rotate: right ? (yax - y) / 15 : -(yax - y) / 15, opacity: op }
  }
  if (y === 3100 && yax > 3250) {
    xpos = right ? yax - y -150 : -(yax - y-150);
    ypos = (yax / 15) - 150;
    // setMyanimation({ transform: `translate(${xpos}px,${ypos}px)` })
    // setTransX(xpos);
    // setTransY(ypos);
    // console.log(transX, ",", transY);
    op = (2500 / (yax) - 0.3);
    fr = op === 0 ?'moved':'';
    let rot = right ? (yax - y) / 15 : -(yax - y) / 15;
    setInitial(fr !== 'moved' ?{ x: 0, y: 0, opacity: 1, rotate: 0 }:{ x: xpos , y : ypos, rotate: right ? (yax - y) / 15 : -(yax - y) / 15, opacity: op })
  
    setRow1style({ transition: "transform 1s" ,  transform: `translate(${xpos}px, ${ypos}px) rotate(${rot}deg)` , opacity: op});
    // return { x: xpos , y : ypos, rotate: right ? (yax - y) / 15 : -(yax - y) / 15, opacity: op }
  }
},[yax])



const handleResize = () => {
  if (window.innerWidth < 720) {
      setMobile(true);
      console.log("Deveice mobile");
  } else {
      setMobile(false)
      console.log("Device big");
  }
  // console.log(isMobile, window.innerWidth);
}



// create an event listener
useEffect(() => {
  window.addEventListener("resize", handleResize)
},[]);


  if (props.motion == 'yes') {
    return (

      <motion.div
        // ref={elementRef}
        initial={mobile? {x:0, y:0, rotate:0} : right ? {x:500,y:100 , rotate: 30 }:{x:-500, y:100 , rotate: -30 }}
        whileInView={{x:0, y:0, rotate:0}}
        // style={{ transform: `translate(500px, 100px)` }}
        transition={transition}
        // style={row1style}
        {...props}>{props.children}</motion.div>
    )
  }
  else if (props.myvideo === 'yes'){

    return(

      <div 
      style={{ opacity:0.5}}
      {...props}
      
      >{props.children}</div>
      
      )
  }
  else {
    return (
      <div {...props}>{props.children}</div>
    )
  }
}
