import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { pageTitle } from '../../helper'
import Accordion from '../Accordion'
import Button from '../Button'
import Cta from '../Cta'
import IconBox from '../IconBox'
import PageHeading from '../PageHeading'
import Div from '../Div'
import SectionHeading from '../SectionHeading'
import TestimonialSlider from '../Slider/TestimonialSlider'
import Spacing from '../Spacing'

export default function ServiceDetailsPage() {
  pageTitle('Service Details');
  const params = useParams();
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      <PageHeading
        title='Service Details'
        bgSrc='/images/service_hero_bg.jpeg'
        pageLinkText={params.serviceDetailsId}
      />
      <Spacing lg='145' md='80' />
      <Div className="container">
        <SectionHeading
          title='Design working process'
          subtitle='Our Process'
          variant='cs-style1 text-center'
        />
        <Spacing lg='90' md='45' />
        <Div className="row">
          <Div className="col-lg-4">
            <IconBox
              icon='/images/square.png'
              title='Strategy & Research'
              subtitle='Groundbreaking campaigns begin with meticulous planning. We delve deep into market trends and audience insights to formulate strategies that ensure your success.'
            />
            <Spacing lg='30' md='30' />
          </Div>
          <Div className="col-lg-4">
            <IconBox
              icon='/images/circle.png'
              title='Wireframing'
              subtitle='Blueprints for Innovation. We meticulously craft wireframes, laying the foundation for seamless user experiences and intuitive interface design.'
            />
            <Spacing lg='30' md='30' />
          </Div>
          <Div className="col-lg-4">
            <IconBox
              icon='/images/triangle.png'
              title='Prototyping'
              subtitle='Bringing Ideas to Life. We create interactive prototypes, allowing you to experience your vision firsthand and refine it for optimal user engagement.'
            />
            <Spacing lg='30' md='30' />
          </Div>
        </Div>
      </Div>
      <Spacing lg='120' md='50' />
      <Div className="container">
        <Div className="row align-items-center">
          <Div className="col-xl-5 col-lg-6">
            <Div className="cs-radius_15 cs-shine_hover_1">
              <img src="/images/service_img_1.jpeg" alt="Service" className='cs-radius_15 w-100' />
            </Div>
            <Spacing lg='0' md='40' />
          </Div>
          <Div className="col-lg-6 offset-xl-1">
            <h2 className="cs-font_50 cs-m0">our most related services</h2>
            <Spacing lg='50' md='30' />
            <Div className="row">
              <Div className="col-lg-6">
                <Button btnLink='/service/service-details' btnText='Web Development' variant='cs-type2' />
                <Spacing lg='20' md='10' />
                <Button btnLink='/service/service-details' btnText='eCommerce Website' variant='cs-type2' />
                <Spacing lg='20' md='10' />
                <Button btnLink='/service/service-details' btnText='Landing page' variant='cs-type2' />
                <Spacing lg='20' md='10' />
                <Button btnLink='/service/service-details' btnText='Mobile App' variant='cs-type2' />
                <Spacing lg='20' md='10' />
                <Button btnLink='/service/service-details' btnText='Game Development' variant='cs-type2' />
                <Spacing lg='20' md='10' />

              </Div>
              <Div className="col-lg-6">
                <Button btnLink='/service/service-details' btnText='Illustration' variant='cs-type2' />
                <Spacing lg='20' md='10' />
                <Button btnLink='/service/service-details' btnText='Creative design' variant='cs-type2' />
                <Spacing lg='20' md='10' />
                <Button btnLink='/service/service-details' btnText='Digital Marketing' variant='cs-type2' />
                <Spacing lg='20' md='10' />
                <Button btnLink='/service/service-details' btnText='Banner, brochure, card' variant='cs-type2' />
                <Spacing lg='20' md='10' />
              </Div>
            </Div>
          </Div>
        </Div>
      </Div>
      <Spacing lg='150' md='80' />
      <TestimonialSlider />
      <Spacing lg='145' md='80' />
      <Div className="container cs-shape_wrap_4">
        <Div className="cs-shape_4"></Div>
        <Div className="cs-shape_4"></Div>
        <Div className="container">
          <Div className="row">
            <Div className="col-xl-5 col-lg-6">
              <SectionHeading
                title='Some pre questions and answers'
                subtitle='FAQ’s'
              />
              <Spacing lg='90' md='45' />
            </Div>
            <Div className="col-lg-6 offset-xl-1">
              <Accordion />
            </Div>
          </Div>
        </Div>
      </Div>
      <Spacing lg='150' md='80' />
      <Div className="container">
        <Cta
          title='Were delivering the best<br>
          customer experience'
          btnText='Apply For Meeting'
          btnLink='/contact'
          bgSrc='/images/cta_bg.jpeg'
        />
      </Div>
    </>
  )
}
